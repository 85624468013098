import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderModule } from '@x/common/loader/loader.module';
import { BrandingModule } from '@x/ecommerce-shop/app/core/shop-ui/components/branding/branding.module';
import { ShopDialogActionsComponent } from './shop-dialog/shop-dialog-actions.component';
import { ShopDialogComponent } from './shop-dialog/shop-dialog.component';

@NgModule({
  declarations: [ShopDialogComponent, ShopDialogActionsComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    LoaderModule,
    MatButtonModule,
    ObserversModule,
    MatTooltipModule,
    FontAwesomeModule,
    BrandingModule,
  ],
  exports: [ShopDialogComponent, ShopDialogActionsComponent],
})
export class ShopDialogUiModule {}
