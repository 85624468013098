import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkColorDirective } from './link-color.directive';
import { SvgColorDirective } from './svg-color.directive';
import { TextColorDirective } from './text-color.directive';

@NgModule({
  declarations: [TextColorDirective, LinkColorDirective, SvgColorDirective],
  exports: [TextColorDirective, LinkColorDirective],
  imports: [CommonModule],
})
export class ShopColorModule {}
