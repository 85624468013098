import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'a[xLinkColor]',
})
export class LinkColorDirective {
  @HostBinding('class')
  color: string = 'link-color-default';

  @Input() set xLinkColor(color: 'primary' | 'accent' | 'warn' | 'default') {
    this.color = `link-color-${color}`;
  }
}
